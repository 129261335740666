<template>
    <div class="analysisPage">
        <t_header :isUserInfo="isUserInfo" @setTodos="getTodos"></t_header>
        <div class="analysisPage_main">
            <div class="analysisPage_main_left">
                <div class="analysisPage_main_left_header">
                    <div class="analysisPage_main_left_header_title">答题进度</div>
                    <div class="analysisPage_main_left_header_progress">
                        <el-progress :percentage="questionBank_info.completeNumber>0?Number(questionBank_info.completeNumber)/Number(questionBank_info.countNumber)*100:0" :show-text="false" :stroke-width="10"></el-progress>
                        <span class="analysisPage_main_left_header_progress_explain">{{questionBank_info.completeNumber}}/{{questionBank_info.countNumber}}</span>
                    </div>
                </div>
                <div class="analysisPage_main_left_smallBox">
                    <div class="analysisPage_main_left_smallBox_btn">
                        <el-button type="primary" plain @click="goBack()">查看报告</el-button>
                    </div>
                    <div>
                        <el-popover placement="top" width="300" trigger="hover" v-model="visible" >
                            <div id="box">
                                <table>
                                    <tr>
                                        <td><input type="button" value="del" @click="del()"></td>
                                        <td><input type="button" value="C" @click="clean()"></td>
                                        <td colspan="2"><input type="text" style="width: 100px" value="" v-model="rel"></td>
                                    </tr>
                                    <tr>
                                        <td><input type="button" value="7" @click="add('7')"></td>
                                        <td><input type="button" value="8" @click="add('8')"></td>
                                        <td><input type="button" value="9" @click="add('9')"></td>
                                        <td><input type="button" value="/" @click="add('/')"></td>
                                    </tr>


                                    <tr>
                                        <td><input type="button" value="4" @click="add('4')"></td>
                                        <td><input type="button" value="5" @click="add('5')"></td>
                                        <td><input type="button" value="6" @click="add('6')"></td>
                                        <td><input type="button" value="*" @click="add('*')"></td>
                                    </tr>

                                    <tr>
                                        <td><input type="button" value="1" @click="add('1')"></td>
                                        <td><input type="button" value="2" @click="add('2')"></td>
                                        <td><input type="button" value="3" @click="add('3')"></td>
                                        <td><input type="button" value="-" @click="add('-')"></td>
                                    </tr>

                                    <tr>
                                        <td><input type="button" value="0" @click="add('0')"></td>
                                        <td><input type="button" value="." @click="add('.')"></td>
                                        <td><input type="button" value="+" @click="add('+')"></td>
                                        <td><input type="button" value="=" v-on:click="result()" ></td>
                                    </tr>
                                </table>
                            </div>
                            <span  slot="reference" @click="visible = !visible" style="display:flex;align-items: center;height: 50px;">
                              
                                <img src="@/assets/exam/tool_icon.png" alt="">
                                <span style="margin-left:10px;">计算器</span>
                            </span>
                        </el-popover>
                    </div>

                </div>
                <div class="analysisPage_main_left_answerSheet">
                    <div class="analysisPage_main_left_answerSheet_header">答题卡</div>
                    <div class="analysisPage_main_left_answerSheet_explain">
                        <p>
                            <span></span>
                            <span>答对</span>
                        </p>
                        <p>
                            <span></span>
                            <span>答错</span>
                        </p>
                        <p>
                            <span></span>
                            <span>未作答</span>
                        </p>
                        <p>
                            <span>
                                <span></span>
                            </span>
                            <span>标记</span>
                        </p>
                    </div>
                    <div class="analysisPage_main_left_answerSheet_listBox">
                        <div class="analysisPage_main_left_answerSheet_listBox_list">
                            <div class="analysisPage_main_left_answerSheet_listBox_list_header" v-if="radioExamList.length>0">【单项选择题】</div>
                            <div class="analysisPage_main_left_answerSheet_listBox_list_item" v-if="radioExamList.length>0">
<!--                                <p v-for="(item,index) in radioExamList" :key="index" :class="{'analysisPage_main_left_answerSheet_listBox_list_item_right':item.isRight==1,'analysisPage_main_left_answerSheet_listBox_list_item_error':item.isRight==2,'analysisPage_main_left_answerSheet_listBox_list_item_right':item.isRight==1}" @click="tabExamQuestion(item,1,index)">{{index+1}} <span v-if="item.isSign==1"></span></p>-->
                                <p style="cursor: pointer" v-for="(item,index) in radioExamList" :key="index" :class="item.className" @click="tabExamQuestion(item,1,index)">{{index+1}} <span v-if="item.isSign==1"></span></p>
                            </div>
                            <div class="analysisPage_main_left_answerSheet_listBox_list_header" v-if="checkboxExamList.length>0">【多项选择题】</div>
                            <div class="analysisPage_main_left_answerSheet_listBox_list_item" v-if="checkboxExamList.length>0">
<!--                                <p v-for="(item,index) in checkboxExamList" :key="index" :class="item.isRight==0?'analysisPage_main_left_answerSheet_listBox_list_item_right':'analysisPage_main_left_answerSheet_listBox_list_item_error'" @click="tabExamQuestion(item,2,index)">{{index+1}} <span v-if="item.isSign==1"></span></p>-->
                                <p style="cursor: pointer" v-for="(item,index) in checkboxExamList" :key="index" :class="item.className" @click="tabExamQuestion(item,2,index)">{{index+1}} <span v-if="item.isSign==1"></span></p>
                            </div>
                            <div class="analysisPage_main_left_answerSheet_listBox_list_header" v-if="estimateList.length>0">【判断题】</div>
                            <div class="analysisPage_main_left_answerSheet_listBox_list_item" v-if="estimateList.length>0">
                                <p style="cursor: pointer" v-for="(item,index) in estimateList" :key="index" :class="item.className" @click="tabExamQuestion(item,3,index)">{{index+1}} <span v-if="item.isSign==1"></span></p>
                            </div>
                            <div class="analysisPage_main_left_answerSheet_listBox_list_header" v-if="budingxiang.length>0">【不定项选择题】</div>
                            <div class="analysisPage_main_left_answerSheet_listBox_list_item" v-if="budingxiang.length>0">
                                <div style="display: flex;" v-for="(budingxiangitem,index) in budingxiang" :key="index"  >
                                    <div @click="tabExamQuestion(budingxiangitem,4,index)"  style="cursor: pointer" v-if="budingxiangitem.subjectList" v-for="(items,indexs) in budingxiangitem.subjectList" :key="indexs" :class="returnClass(items)">{{(index+1)+'.'+(indexs+1)}} <span v-if="items.isSign==1"></span></div>
                                </div>
                            </div>
                            <div class="analysisPage_main_left_answerSheet_listBox_list_header" v-if="jisuanfenxi.length>0">【计算分析题】</div>
                            <div class="analysisPage_main_left_answerSheet_listBox_list_item" v-if="jisuanfenxi.length>0">
                                <div style="display: flex;" v-for="(item,index) in jisuanfenxi" :key="index"  >
                                    <div @click="tabExamQuestion(item,5,index)"  style="cursor: pointer" v-if="item.subjectList" v-for="(items,indexs) in item.subjectList" :key="indexs" :class="returnClass(items)">{{(index+1)+'.'+(indexs+1)}} <span v-if="items==1"></span></div>
                                </div>
                                <!-- <p style="cursor: pointer" v-for="(item,index) in jisuanfenxi" :key="index" :class="item.className" @click="tabExamQuestion(item,5,index)">{{index+1}} <span v-if="item.isSign==1"></span></p> -->
                            </div>
                            <div class="analysisPage_main_left_answerSheet_listBox_list_header" v-if="synthesisList.length>0">【综合题】</div>
                            <div class="analysisPage_main_left_answerSheet_listBox_list_item" v-if="synthesisList.length>0">
                                <div style="display: flex;" v-for="(item,index) in synthesisList" :key="index"  >
                                    <div @click="tabExamQuestion(item,5,index)"  style="cursor: pointer" v-if="item.subjectList" v-for="(items,indexs) in item.subjectList" :key="indexs" :class="returnClass(items)">{{(index+1)+'.'+(indexs+1)}} <span v-if="items==1"></span></div>
                                </div>

                                <!-- <p style="cursor: pointer" v-for="(item,index) in synthesisList" :key="index" :class="item.className" @click="tabExamQuestion(item,6,index)">{{index+1}} <span v-if="item.isSign==1"></span></p> -->
                            </div>
                            <div class="analysisPage_main_left_answerSheet_listBox_list_header" v-if="shortAnswerList.length>0">【简答题】</div>
                            <div class="analysisPage_main_left_answerSheet_listBox_list_item" v-if="shortAnswerList.length>0">
                                <div style="display: flex;" v-for="(item,index) in shortAnswerList" :key="index"  >
                                    <div @click="tabExamQuestion(item,5,index)"  style="cursor: pointer" v-if="item.subjectList" v-for="(items,indexs) in item.subjectList" :key="indexs" :class="returnClass(items)">{{(index+1)+'.'+(indexs+1)}} <span v-if="items==1"></span></div>
                                </div>
                                <!-- <p style="cursor: pointer" v-for="(item,index) in shortAnswerList" :key="index" :class="item.className" @click="tabExamQuestion(item,7,index)">{{index+1}} <span v-if="item.isSign==1"></span></p> -->
                            </div>
                            <div class="analysisPage_main_left_answerSheet_listBox_list_header" v-if="jisuanhuida.length>0">【计算回答题】</div>
                            <div class="analysisPage_main_left_answerSheet_listBox_list_item" v-if="jisuanhuida.length>0">
                                <div style="display: flex;" v-for="(item,index) in jisuanhuida" :key="index"  >
                                    <div @click="tabExamQuestion(item,5,index)"  style="cursor: pointer" v-if="item.subjectList" v-for="(items,indexs) in item.subjectList" :key="indexs" :class="returnClass(items)">{{(index+1)+'.'+(indexs+1)}} <span v-if="items==1"></span></div>
                                </div>
                                <!-- <p style="cursor: pointer" v-for="(item,index) in jisuanhuida" :key="index" :class="item.className" @click="tabExamQuestion(item,8,index)">{{index+1}} <span v-if="item.isSign==1"></span></p> -->
                            </div>
                            <div class="analysisPage_main_left_answerSheet_listBox_list_header" v-if="anlifenxi.length>0">【案例分析题】</div>
                            <div class="analysisPage_main_left_answerSheet_listBox_list_item" v-if="anlifenxi.length>0">
                                <div style="display: flex;" v-for="(item,index) in anlifenxi" :key="index"  >
                                    <div @click="tabExamQuestion(item,5,index)"  style="cursor: pointer" v-if="item.subjectList" v-for="(items,indexs) in item.subjectList" :key="indexs" :class="returnClass(items)">{{(index+1)+'.'+(indexs+1)}} <span v-if="items==1"></span></div>
                                </div>
                                <!-- <p style="cursor: pointer" v-for="(item,index) in anlifenxi" :key="index" :class="item.className" @click="tabExamQuestion(item,9,index)">{{index+1}} <span v-if="item.isSign==1"></span></p> -->
                            </div>
                            <div class="analysisPage_main_left_answerSheet_listBox_list_header" v-if="jisuan.length>0">【计算题】</div>
                            <div class="analysisPage_main_left_answerSheet_listBox_list_item" v-if="jisuan.length>0">
                                <div style="display: flex;" v-for="(item,index) in jisuan" :key="index"  >
                                    <div @click="tabExamQuestion(item,5,index)"  style="cursor: pointer" v-if="item.subjectList" v-for="(items,indexs) in item.subjectList" :key="indexs" :class="returnClass(items)">{{(index+1)+'.'+(indexs+1)}} <span v-if="items==1"></span></div>
                                </div>
                                <!-- <p style="cursor: pointer" v-for="(item,index) in jisuan" :key="index" :class="item.className" @click="tabExamQuestion(item,10,index)">{{index+1}} <span v-if="item.isSign==1"></span></p> -->
                            </div>
                            <div class="analysisPage_main_left_answerSheet_listBox_list_header" v-if="zonghefenxi.length>0">【综合分析题】</div>
                            <div class="analysisPage_main_left_answerSheet_listBox_list_item" v-if="zonghefenxi.length>0">
                                <div style="display: flex;" v-for="(item,index) in zonghefenxi" :key="index"  >
                                    <div @click="tabExamQuestion(item,5,index)"  style="cursor: pointer" v-if="item.subjectList" v-for="(items,indexs) in item.subjectList" :key="indexs" :class="returnClass(items)">{{(index+1)+'.'+(indexs+1)}} <span v-if="items==1"></span></div>
                                </div>
                                <!-- <p style="cursor: pointer" v-for="(item,index) in zonghefenxi" :key="index" :class="item.className" @click="tabExamQuestion(item,11,index)">{{index+1}} <span v-if="item.isSign==1"></span></p> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="analysisPage_main_right">
                <div class="analysisPage_main_right_header">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/tiku' }">在线题库</el-breadcrumb-item>
                        <el-breadcrumb-item>{{pageExamObj.subjectFirstTypeName}}
                          <span v-if="pageExamObj.subjectSecondTypeName">、{{pageExamObj.subjectSecondTypeName}}</span>
                          <span v-if="pageExamObj.subjectChapterName">、{{pageExamObj.subjectChapterName}}</span>
                          <span v-if="pageExamObj.subjectNodeName">、{{pageExamObj.subjectNodeName}}</span>
                        </el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
                <div class="analysisPage_main_right_content">
                    <span class="analysisPage_main_right_content_position"></span>
                    <div class="analysisPage_main_right_content_title">
                        <span v-if="pageExamObj.classify=='1'">单项选择题</span>
                        <span v-if="pageExamObj.classify=='2'">多项选择题</span>
                        <span v-if="pageExamObj.classify=='3'">判断题</span>
                        <span v-if="pageExamObj.classify=='4'">不定项选择题</span>
                        <span v-if="pageExamObj.classify=='5'">计算分析题</span>
                        <span v-if="pageExamObj.classify=='6'">综合题</span>
                        <span v-if="pageExamObj.classify=='7'">简答题</span>
                        <span v-if="pageExamObj.classify=='8'">计算回答题</span>
                        <span v-if="pageExamObj.classify=='9'">案例分析题</span>
                        <span v-if="pageExamObj.classify=='10'">计算题</span>
                        <span v-if="pageExamObj.classify=='11'">综合分析题</span>
                        <!-- <span>本类型共10题，每小题3分，共30分</span> -->
                    </div>
                    <!-- <div class="analysisPage_main_right_content_stide">
                        
                        <div class="analysisPage_main_right_content_stide_right">
                            <p @click="tabCollec()">
                          
                                <img src="@/assets/exam/collect_icon.png" alt="">
                                <span v-if="pageExamObj.isCollect==2">收藏</span>
                                <span v-if="pageExamObj.isCollect==1">取消收藏</span>
                            </p>
                            <p @click="stamp()">
                           
                                <img src="@/assets/exam/sign_icon.png" alt="">
                                <span v-if="pageExamObj.isSign==2">标记</span>
                                <span v-if="pageExamObj.isSign==1">取消标记</span>
                            </p>
                            <p @click="analysis()">
                             
                                <span v-if="isShowAnalysis==true">隐藏解析</span>
                                <span v-if="isShowAnalysis==false">显示解析</span>
                            </p>
                        </div>
                    </div> -->
                    <div class="analysisPage_main_right_content_question">
                        <div class="analysisPage_main_right_content_question_title">
                            <div class="analysisPage_main_right_content_stide_left">
                                <img src="@/assets/exam/num_banner.png" alt="">
                                <span>{{indexIng}}</span>
                            </div>
                            <div style="margin-left:10px">
                                <div v-html="pageExamObj.subjectTitle">
                                    <!-- {{}} -->
                                </div>
                            </div>
                            
                        </div>
                        <div v-if="pageExamObj.answerList && pageExamObj.answerList.length" class="analysisPage_main_right_content_question_options">
                            <p v-for="(item,index) in pageExamObj.answerList" :key="index">
                                <span :class="pageExamObj.doneAnswerIds!=item.answerId?item.isRight==1?'analysisPage_main_right_content_question_options_right':'analysisPage_main_right_content_question_options_other':item.isRight==0?'analysisPage_main_right_content_question_options_error':'analysisPage_main_right_content_question_options_right'" >{{item.indexes}}</span>
                                <!-- 'analysisPage_main_right_content_question_options_right' -->
                                <!-- <span :class="pageExamObj.doneAnswerIds!=item.answerId && item.isRight==0?'analysisPage_main_right_content_question_options_other':''" v-if="pageExamObj.doneAnswerIds!=item.answerId && item.isRight==0">{{item.indexes}}</span> -->
                                <span class="analysisPage_main_right_content_question_options_text">{{item.answerName}}</span>
                            </p>
                        </div>
                        <div v-else-if="pageExamObj.subjectList && pageExamObj.subjectList.length" >
                            <div v-for="(subject,subjectIdx) in pageExamObj.subjectList" :key="subjectIdx" class="analysisPage_main_right_content_question_options">
                                <div class="analysisPage_main_right_content_question_title">{{subjectIdx+1}}、{{subject.subjectTitle}}</div>
                                <p v-for="(item,index) in subject.answerList" :key="index">
                                    <span :class="subject.doneAnswerIds!=item.answerId?item.isRight==1?'analysisPage_main_right_content_question_options_right':'analysisPage_main_right_content_question_options_other':item.isRight==0?'analysisPage_main_right_content_question_options_error':'analysisPage_main_right_content_question_options_right'" >{{item.indexes}}</span>
                                    <!-- 'analysisPage_main_right_content_question_options_right' -->
                                    <!-- <span :class="pageExamObj.doneAnswerIds!=item.answerId && item.isRight==0?'analysisPage_main_right_content_question_options_other':''" v-if="pageExamObj.doneAnswerIds!=item.answerId && item.isRight==0">{{item.indexes}}</span> -->
                                    <span class="analysisPage_main_right_content_question_options_text">{{item.answerName}}</span>
                                </p>
                                <div class="analysisPage_main_right_content_explain" v-if="isShowAnalysis">
                                    <div class="analysisPage_main_right_content_explain_left">
                                      <img v-show="subject.chooseAnswer != subject.referenceAnswer" src="@/assets/exam/error.png" alt="">
                                      <span style="margin:0 20px">
                                          <span class="analysisPage_main_right_content_explain_left_leftBox">您的答案：</span>
                                          <span class="analysisPage_main_right_content_explain_left_errorBox">{{subject.chooseAnswer}}</span>
                                      </span>
                                      <span v-show="subject.referenceAnswer">
                                          <span class="analysisPage_main_right_content_explain_left_leftBox">参考答案：</span>
                                          <span class="analysisPage_main_right_content_explain_left_rightBox">{{subject.referenceAnswer}}</span>
                                      </span>
                                    </div>
                                    <div class="analysisPage_main_right_content_explain_right">
                                      <!-- <p style="min-width:120px">做对次数：{{subject.rightNumber}}次</p>
                                      <p style="min-width:110px">累计作答：{{pageExamObj.countNumber}}次</p> -->
                                    </div>
                                </div>
                                <div class="analysisPage_main_right_content_testing" v-if="subject.knowledgeList!=null && subject.knowledgeList.length>0 && isShowAnalysis">
                                <!-- <p >考点：</p> -->
                                <p >
                                    <span v-for="(item,index) in subject.knowledgeList" :key="index">{{item.knowledgeName}}</span>
                                </p>
                                </div>
                                <div class="analysisPage_main_right_content_analysis" v-if="subject.subjectTitle && isShowAnalysis">
                                    <div class="analysisPage_main_right_content_analysis_title">答案解析：</div>
                                    <div class="analysisPage_main_right_content_analysis_text" v-html="subject.analysisText"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="isShowAnalysis && (pageExamObj.classify=='1' || pageExamObj.classify=='2' || pageExamObj.classify=='3')">
                        <div class="analysisPage_main_right_content_explain">
                            <div class="analysisPage_main_right_content_explain_left">
                                <img v-show="pageExamObj.chooseAnswer != pageExamObj.referenceAnswer" src="@/assets/exam/error.png" alt="">
                                <p>
                                    <span  class="analysisPage_main_right_content_explain_left_leftBox">您的答案：</span>
                                    <span  class="analysisPage_main_right_content_explain_left_errorBox">{{pageExamObj.chooseAnswer}}</span>
                                </p>
                                <p v-show="pageExamObj.referenceAnswer">
                                    <span class="analysisPage_main_right_content_explain_left_leftBox">参考答案：</span>
                                    <span class="analysisPage_main_right_content_explain_left_rightBox">{{pageExamObj.referenceAnswer}}</span>
                                </p>
                            </div>
                            <div class="analysisPage_main_right_content_explain_right">
                                <!-- <p>
                                    <span>本题用时：</span><span>277</span><span>秒</span>
                                </p>
                                <p>
                                    <span>做对次数：</span><span>{{pageExamObj.rightNumber}}</span><span>次</span>
                                </p>
                                <p>
                                    <span>累计作答：</span><span>{{pageExamObj.countNumber}}</span><span>次</span>
                                </p> -->
                            </div>
                        </div>
                        <div class="analysisPage_main_right_content_testing" v-if="pageExamObj.knowledgeList!=null && pageExamObj.knowledgeList.length>0">
                            <p >考点：</p>
                            <p >
                                <span v-for="(item,index) in pageExamObj.knowledgeList" :key="index">{{item.knowledgeName}}</span>
                            </p>
                        </div>
                        <div class="analysisPage_main_right_content_analysis" v-if="pageExamObj.subjectTitle!=null && pageExamObj.subjectTitle!='' && pageExamObj.subjectTitle!=undefined">
                            <div class="analysisPage_main_right_content_analysis_title">答案解析：</div>
                            <div class="analysisPage_main_right_content_analysis_text" v-html="pageExamObj.analysisText"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <t_footer></t_footer>
    </div>
</template>

<script>
import { Loading } from 'element-ui';
import t_header from '@/components/t_header/index.vue'
import t_footer from '@/components/t_footer/index.vue'
import { assignment_exercise,analysis_list,analysis_new,subject_analysisList,analysisList_new,assignment_paper,add_collect,deleted_collect,add_sign } from "@/api/exam";
let oClassify = {
  1:'radioExamList',
  2:'checkboxExamList',
  3:'estimateList',
  4:'budingxiang',
  5:'jisuanfenxi',
  6:'synthesisList',
  7:'shortAnswerList',
  8:'jisuanhuida',
  9:'anlifenxi',
  10:'jisuan',
  11:'zonghefenxi',
}
export default {
    name:'analysisPage',
    components: {
        t_header,
        t_footer,
    },
    data(){
        return{
            examTextObj:{},// 试卷的信息
            radioExamList:[],// 单选题集合
            checkboxExamList:[],// 多选题集合
            estimateList:[],// 判断体集合
            budingxiang:[],//不定向选择题
            jisuanfenxi:[],//计算分析题
            synthesisList:[],// 综合体集合
            shortAnswerList:[],// 简答题集合
            jisuanhuida:[],// 计算回答
            anlifenxi:[],//案例分析
            jisuan:[],//计算
            zonghefenxi:[],//综合分析
            pageExamObj:{}, //每一题的信息
            checkedExamId:'', // 左边栏选中的题id
            indexIng:1,// 点击每个数组的index
            titleIndex:0,
            smallQuestionIndex:'',// 综合题的小题index
            examInfo:{},// 试卷的上级信息
            tabIndex:0,
            twoIindex:0,
            num:0,
            nodeType:0,
            isError:0,
            questionBank_info:{},
            referenceAnswer:'',
            chooseAnswer:'',
            knowledgeList:[],
            isUserInfo:false,
            visible:false,// 是否显示计算器
            rel:"",// 计算
            isShowAnalysis:true,
        }
    },
    mounted(){
        this.tabIndex=this.$route.query.tabIndex
        this.twoIindex=this.$route.query.twoIindex
        this.num=this.$route.query.num
        this.isError=this.$route.query.isError
        this.nodeType=this.$route.query.nodeType
        this.testPaperId=this.$route.query.testPaperId
        this.nodeId=this.$route.query.nodeId
        if(this.tabIndex!='' && this.tabIndex!=null && this.tabIndex!=undefined){
            this.nodeType=this.tabIndex
        }
        if(this.num==1){
            this.nodeId=this.$route.query.nodeId
            this.assignment_exercise()
            //this.analysis_list();
            this.analysis_new();
        }else{
            // this.nodeType=2
            this.testPaperId=this.$route.query.testPaperId
            // this.isTrue=num
            this.assignment_paper()
            //this.subject_analysisList()
            this.analysisList_new();
        }

    },
    methods:{
        returnClass(item){
            if (item.isRight == 1) {
                return 'testReport_main_answerSheet_item_right';
                //错误
            } else if (item.isRight == 2) {
                return  'testReport_main_answerSheet_item_option';
                //未作答
            } else {
                return 'testReport_main_answerSheet_item_unanswered';
            }
        },
        // 是否展示解析
        analysis(){
            this.isShowAnalysis=!this.isShowAnalysis
        },
        // 计算器
        add(index){//这里就是按键绑定的方法，把获取到的值拼接到rel字符串上
            this.rel +=index;
        },
        result(){
            this.rel = eval(this.rel);//这里是用eval方法进行一个计算
            this.rel = String(this.rel);//这里的目的是为了显示在显示栏的数字还是字符串，只有字符串才能进行退格，归零的操作
        },
        del(){//这个就是退格的操作，用字符串的substring方法截取，每一次截取都是从第0个开始，到长度的前一个，就相当于退格了。
        this.rel = this.rel.substring(0,this.rel.length-1);
        },
        clean(){//这里就是归零的方法，通过给结果赋一个空字符串的方法来实现归零的操作，当然也可以使用删除的方法，例如unshift方法或者pop方法，直接赋值为空比较简便。
                this.rel = "";
        },
        // goBack
        goBack(){
            this.$router.go(-1)
        },
        // 父传子
        getTodos(e){
            this.isUserInfo=e
        },
        // 切换题
        tabExamQuestion(item,num,index){
            this.checkedExamId=item.subjectId
            this.pageExamObj=item
            this.titleIndex=num
            this.indexIng=index+1
            // this.isShowAnalysis=false
        },
        // 章节和专项练习
        assignment_exercise(){
            var obj={
                nodeId:this.nodeId,
                nodeType:this.nodeType
            }
            assignment_exercise(obj).then(res=>{
                if(res.data.code==0){
                    this.questionBank_info=res.data.data
                }else{
                    this.$message.error(res.data.msg)
                }
            }).catch(error=>{
                console.log(error)
            })
        },
        //收藏
        // handleCollect(subject){
        //   subject.isCollect = subject.isCollect == 1?2:1
        //   if(subject.isCollect == 1){
        //     add_collect({ subjectId:subject.subjectId }).then(res=>{})
        //   }else{
        //     deleted_collect({
        //       subjectIds:subject.subjectId
        //     }).then(res=>{ })
        //   }
        // },
        //标记
        // handleSign(subject){
        //   subject.isSign = subject.isSign == 1?2:1
        //   const oData = {
        //     type:subject.isSign,
        //     subjectId: subject.subjectId,
        //   }
        //   this.nodeId && (oData.nodeId = this.nodeId)
        //   this.testPaperId && (oData.testPaperId = this.testPaperId)
        //   add_sign(oData).then(res=>{})
        // },
        analysis_new(){
            this.loading = Loading.service({text:'Loading...',background:'rgba(0, 0, 0, 0.1)'})
            var that=this
            analysis_new({
                nodeId:that.nodeId,
                nodeType:that.nodeType,
                type:that.isError,
            }).then(res=>{
                if(res.data.code==0){
                  let dataList = that.analysisObj=res.data.data
                  let myKeys = [];
                  let rightKeys = [];
                  let nowitem = ''
                  let doneAnswerIds = ''
                  for(var i in dataList){
                    nowitem = dataList[i]
                      //正确
                      if(nowitem.isRight == 1){
                          nowitem.className = 'analysisPage_main_left_answerSheet_listBox_list_item_right';
                          //错误
                      }else if(nowitem.isRight == 2){
                          nowitem.className = 'analysisPage_main_left_answerSheet_listBox_list_item_error';
                          //未作答
                      }else{
                          nowitem.className = 'analysisPage_main_left_answerSheet_listBox_list_item_unanswered';
                      }
                      if(nowitem.classify < 5){
                          myKeys = [];
                          rightKeys = [];
                          nowitem.doneAnswerIds && (doneAnswerIds = nowitem.doneAnswerIds.split(','))
                          nowitem.answerList.forEach(item=>{
                            item.isRight && rightKeys.push(item.indexes);
                            doneAnswerIds.includes(item.answerId) && myKeys.push(item.indexes)
                          })
                          nowitem.chooseAnswer= myKeys.join(',');
                          nowitem.referenceAnswer= rightKeys.join(',');
                      }else if(nowitem.subjectList.length == 0){
                          nowitem.chooseAnswer = nowitem.doneAnswer;
                      }
                      nowitem.chooseAnswer || (nowitem.chooseAnswer = '未作答');
                      that[oClassify[nowitem.classify]].push(nowitem)
                      
                  }
                  for(let key in oClassify){
                    if(that[oClassify[key]].length){
                      that.pageExamObj = that[oClassify[key]][0]
                      break;
                    }
                  }
                  //console.log('that.pageExamObj',that.checkboxExamList);
                   this.loading.close()
              }else{
                  that.$message.error(res.data.msg)
              }
            }).catch(error=>{
                console.log(error)
            })

        },
        // 章节练习的解析
        analysis_list(){
            this.loading = Loading.service({text:'Loading...',background:'rgba(0, 0, 0, 0.1)'})
            var that=this
            analysis_list({
                nodeId:that.nodeId,
                nodeType:that.nodeType,
                type:that.isError,
            }).then(res=>{
                if(res.data.code==0){
                  let dataList = that.analysisObj=res.data.data
                  let myKeys = [];
                  let rightKeys = [];
                  let nowitem = ''
                  let doneAnswerIds = ''
                  for(var i in dataList){
                    nowitem = dataList[i]
                      //正确
                      if(nowitem.isRight == 1){
                          nowitem.className = 'analysisPage_main_left_answerSheet_listBox_list_item_right';
                          //错误
                      }else if(nowitem.isRight == 2){
                          nowitem.className = 'analysisPage_main_left_answerSheet_listBox_list_item_error';
                          //未作答
                      }else{
                          nowitem.className = 'analysisPage_main_left_answerSheet_listBox_list_item_unanswered';
                      }
                      if(nowitem.classify < 5){
                          myKeys = [];
                          rightKeys = [];
                          nowitem.doneAnswerIds && (doneAnswerIds = nowitem.doneAnswerIds.split(','))
                          nowitem.answerList.forEach(item=>{
                            item.isRight && rightKeys.push(item.indexes);
                            doneAnswerIds.includes(item.answerId) && myKeys.push(item.indexes)
                          })
                          nowitem.chooseAnswer= myKeys.join(',');
                          nowitem.referenceAnswer= rightKeys.join(',');
                      }else if(nowitem.subjectList.length == 0){
                          nowitem.chooseAnswer = nowitem.doneAnswer;
                      }
                      nowitem.chooseAnswer || (nowitem.chooseAnswer = '未作答');
                      that[oClassify[nowitem.classify]].push(nowitem)
                      
                  }
                  for(let key in oClassify){
                    if(that[oClassify[key]].length){
                      that.pageExamObj = that[oClassify[key]][0]
                      break;
                    }
                  }
                  //console.log('that.pageExamObj',that.checkboxExamList);
                   this.loading.close()
              }else{
                  that.$message.error(res.data.msg)
              }
            }).catch(error=>{
                console.log(error)
            })
        },
        // 历年/模拟/考前试卷-交卷or答题报告
        assignment_paper(){
            assignment_paper({
                testPaperId:this.testPaperId,
                terrace:'2',
            }).then(res=>{
                if(res.data.code==0){
                    this.questionBank_info=res.data.data
                }else{
                    this.$message.error(res.data.msg)
                }
            }).catch(error=>{
                console.log(error)
            })
        },
        analysisList_new(){// 历年/模拟/考前试卷-题解析列表 -- new
            this.loading = Loading.service({text:'Loading...',background:'rgba(0, 0, 0, 0.1)'})
            var that=this

            analysisList_new({
                testPaperId:that.testPaperId,
                type:that.isError,
            }).then(res=>{
                if(res.data.code==0){
                    let dataList = that.analysisObj=res.data.data
                    let nowitem = ''
                    let myKeys = [];
                    let rightKeys = [];
                    let doneAnswerIds = ''
                    for(var i in dataList){
                      nowitem = dataList[i]
                        //正确
                        if(nowitem.isRight == 1){
                            nowitem.className = 'analysisPage_main_left_answerSheet_listBox_list_item_right';
                            //错误
                        }else if(nowitem.isRight == 2){
                            nowitem.className = 'analysisPage_main_left_answerSheet_listBox_list_item_error';
                            //未作答
                        }else{
                            nowitem.className = 'analysisPage_main_left_answerSheet_listBox_list_item_unanswered';
                        }
                        if(nowitem.classify < 5 && nowitem.answerList && nowitem.answerList.length){
                          myKeys = [];
                          rightKeys = [];
                          nowitem.doneAnswerIds && (doneAnswerIds = nowitem.doneAnswerIds.split(','))
                          nowitem.answerList.forEach(item=>{
                            item.isRight && rightKeys.push(item.indexes);
                            doneAnswerIds.includes(item.answerId) && myKeys.push(item.indexes)
                          })
                          nowitem.chooseAnswer= myKeys.join(',');
                          nowitem.referenceAnswer= rightKeys.join(',');
                        }else if(nowitem.classify=="4"){
                          nowitem.subjectList.forEach(item=>{
                            myKeys = [];
                            rightKeys = [];
                            item.doneAnswerIds && (doneAnswerIds = item.doneAnswerIds.split(','))
                            item.answerList.forEach(item2=>{
                              item2.isRight && rightKeys.push(item2.indexes);
                              doneAnswerIds.includes(item2.answerId) && myKeys.push(item2.indexes)
                            })
                            item.chooseAnswer= myKeys.join(',')||'未作答';
                            item.referenceAnswer= rightKeys.join(',');
                          })
                        }
                        nowitem.chooseAnswer || (nowitem.chooseAnswer = '未作答');
                        that[oClassify[nowitem.classify]].push(nowitem)
                    }
                    for(let key in oClassify){
                      if(that[oClassify[key]].length){
                        that.pageExamObj = that[oClassify[key]][0]
                        break;
                      }
                    }
                    //console.log('that.pageExamObj',that.checkboxExamList);
                     that.loading.close()
                }else{
                    that.$message.error(res.data.msg)
                }
            }).catch(error=>{
                console.log(error)
            })

        },
        // 历年/模拟/考前试卷-题解析列表
        subject_analysisList(){
            this.loading = Loading.service({text:'Loading...',background:'rgba(0, 0, 0, 0.1)'})
            var that=this

            subject_analysisList({
                testPaperId:that.testPaperId,
                type:that.isError,
            }).then(res=>{
                if(res.data.code==0){
                    let dataList = that.analysisObj=res.data.data
                    let nowitem = ''
                    let myKeys = [];
                    let rightKeys = [];
                    let doneAnswerIds = ''
                    for(var i in dataList){
                      nowitem = dataList[i]
                        //正确
                        if(nowitem.isRight == 1){
                            nowitem.className = 'analysisPage_main_left_answerSheet_listBox_list_item_right';
                            //错误
                        }else if(nowitem.isRight == 2){
                            nowitem.className = 'analysisPage_main_left_answerSheet_listBox_list_item_error';
                            //未作答
                        }else{
                            nowitem.className = 'analysisPage_main_left_answerSheet_listBox_list_item_unanswered';
                        }
                        if(nowitem.classify < 5 && nowitem.answerList && nowitem.answerList.length){
                          myKeys = [];
                          rightKeys = [];
                          nowitem.doneAnswerIds && (doneAnswerIds = nowitem.doneAnswerIds.split(','))
                          nowitem.answerList.forEach(item=>{
                            item.isRight && rightKeys.push(item.indexes);
                            doneAnswerIds.includes(item.answerId) && myKeys.push(item.indexes)
                          })
                          nowitem.chooseAnswer= myKeys.join(',');
                          nowitem.referenceAnswer= rightKeys.join(',');
                        }else if(nowitem.classify=="4"){
                          nowitem.subjectList.forEach(item=>{
                            myKeys = [];
                            rightKeys = [];
                            item.doneAnswerIds && (doneAnswerIds = item.doneAnswerIds.split(','))
                            item.answerList.forEach(item2=>{
                              item2.isRight && rightKeys.push(item2.indexes);
                              doneAnswerIds.includes(item2.answerId) && myKeys.push(item2.indexes)
                            })
                            item.chooseAnswer= myKeys.join(',')||'未作答';
                            item.referenceAnswer= rightKeys.join(',');
                          })
                        }
                        nowitem.chooseAnswer || (nowitem.chooseAnswer = '未作答');
                        that[oClassify[nowitem.classify]].push(nowitem)
                    }
                    for(let key in oClassify){
                      if(that[oClassify[key]].length){
                        that.pageExamObj = that[oClassify[key]][0]
                        break;
                      }
                    }
                    //console.log('that.pageExamObj',that.checkboxExamList);
                     that.loading.close()
                }else{
                    that.$message.error(res.data.msg)
                }
            }).catch(error=>{
                console.log(error)
            })
        },

        // 删除收藏
        deleted_collect(obj){
            deleted_collect(obj).then(res=>{
                if(res.data.code==0){
                    this.pageExamObj.isCollect==2
                    if(this.pageExamObj.classify=='1'){
                        for(var i in this.radioExamList){
                            if(this.radioExamList[i].subjectId==this.pageExamObj.subjectId){
                                this.radioExamList[i].isCollect=2
                            }
                        }
                    }else if(this.pageExamObj.classify=='2'){
                        for(var i in this.checkboxExamList){
                            if(this.checkboxExamList[i].subjectId==this.pageExamObj.subjectId){
                                this.checkboxExamList[i].isCollect=2
                            }
                        }
                    }else if(this.pageExamObj.classify=='3'){
                        for(var i in this.estimateList){
                            if(this.estimateList[i].subjectId==this.pageExamObj.subjectId){
                                this.estimateList[i].isCollect=2
                            }
                        }
                    }else if(this.pageExamObj.classify=='4'){
                        for(var i in this.budingxiang){
                            if(this.budingxiang[i].subjectId==this.pageExamObj.subjectId){
                                this.budingxiang[i].isCollect=2
                            }
                        }
                    }else if(this.pageExamObj.classify=='5'){
                        for(var i in this.jisuanfenxi){
                            if(this.jisuanfenxi[i].subjectId==this.pageExamObj.subjectId){
                                this.jisuanfenxi[i].isCollect=2
                            }
                        }
                    }else if(this.pageExamObj.classify=='6'){
                        for(var i in this.synthesisList){
                            if(this.synthesisList[i].subjectId==this.pageExamObj.subjectId){
                                this.synthesisList[i].isCollect=2
                            }
                        }
                    }else if(this.pageExamObj.classify=='7'){
                        for(var i in this.shortAnswerList){
                            if(this.shortAnswerList[i].subjectId==this.pageExamObj.subjectId){
                                this.shortAnswerList[i].isCollect=2
                            }
                        }
                    }else if(this.pageExamObj.classify=='8'){
                        for(var i in this.jisuanhuida){
                            if(this.jisuanhuida[i].subjectId==this.pageExamObj.subjectId){
                                this.jisuanhuida[i].isCollect=2
                            }
                        }
                    }else if(this.pageExamObj.classify=='9'){
                        for(var i in this.anlifenxi){
                            if(this.anlifenxi[i].subjectId==this.pageExamObj.subjectId){
                                this.anlifenxi[i].isCollect=2
                            }
                        }
                    }else if(this.pageExamObj.classify=='10'){
                        for(var i in this.jisuan){
                            if(this.jisuan[i].subjectId==this.pageExamObj.subjectId){
                                this.jisuan[i].isCollect=2
                            }
                        }
                    }else if(this.pageExamObj.classify=='11'){
                        for(var i in this.zonghefenxi){
                            if(this.zonghefenxi[i].subjectId==this.pageExamObj.subjectId){
                                this.zonghefenxi[i].isCollect=2
                            }
                        }
                    }
                }
            }).catch(error=>{
                console.log(error)
            })
        },
        // 添加收藏
        add_collect(obj){
            add_collect(obj).then(res=>{
                if(res.data.code==0){
                    this.pageExamObj.isCollect==1
                    if(this.pageExamObj.classify=='1'){
                        this.pageExamObj.isCollect==1
                        for(var i in this.radioExamList){
                            if(this.radioExamList[i].subjectId==this.pageExamObj.subjectId){
                                this.radioExamList[i].isCollect=1
                            }
                        }
                    }else if(this.pageExamObj.classify=='2'){
                        
                        for(var i in this.checkboxExamList){
                            if(this.checkboxExamList[i].subjectId==this.pageExamObj.subjectId){
                                this.checkboxExamList[i].isCollect=1
                            }
                        }
                    }else if(this.pageExamObj.classify=='3'){
                        
                        for(var i in this.estimateList){
                            if(this.estimateList[i].subjectId==this.pageExamObj.subjectId){
                                this.estimateList[i].isCollect=1
                            }
                        }
                    }else if(this.pageExamObj.classify=='4'){
                        
                        for(var i in this.budingxiang){
                            if(this.budingxiang[i].subjectId==this.pageExamObj.subjectId){
                                this.budingxiang[i].isCollect=1
                            }
                        }
                    }else if(this.pageExamObj.classify=='5'){
                        
                        for(var i in this.jisuanfenxi){
                            if(this.jisuanfenxi[i].subjectId==this.pageExamObj.subjectId){
                                this.jisuanfenxi[i].isCollect=1
                            }
                        }
                    }else if(this.pageExamObj.classify=='6'){
                        
                        for(var i in this.synthesisList){
                            if(this.synthesisList[i].subjectId==this.pageExamObj.subjectId){
                                this.synthesisList[i].isCollect=1
                            }
                        }
                    }else if(this.pageExamObj.classify=='7'){
                        
                        for(var i in this.shortAnswerList){
                            if(this.shortAnswerList[i].subjectId==this.pageExamObj.subjectId){
                                this.shortAnswerList[i].isCollect=1
                            }
                        }
                    }else if(this.pageExamObj.classify=='8'){
                        
                        for(var i in this.jisuanhuida){
                            if(this.jisuanhuida[i].subjectId==this.pageExamObj.subjectId){
                                this.jisuanhuida[i].isCollect=1
                            }
                        }
                    }else if(this.pageExamObj.classify=='9'){
                        
                        for(var i in this.anlifenxi){
                            if(this.anlifenxi[i].subjectId==this.pageExamObj.subjectId){
                                this.anlifenxi[i].isCollect=1
                            }
                        }
                    }else if(this.pageExamObj.classify=='10'){
                        
                        for(var i in this.jisuan){
                            if(this.jisuan[i].subjectId==this.pageExamObj.subjectId){
                                this.jisuan[i].isCollect=1
                            }
                        }
                    }else if(this.pageExamObj.classify=='11'){
                        
                        for(var i in this.zonghefenxi){
                            if(this.zonghefenxi[i].subjectId==this.pageExamObj.subjectId){
                                this.zonghefenxi[i].isCollect=1
                            }
                        }
                    }
                    
                }
            }).catch(error=>{
                console.log(error)
            })
        },
        // 点击收藏
        tabCollec(){
            if(this.pageExamObj.isCollect==1){
                var obj={
                    subjectIds:this.pageExamObj.subjectId,
                }
                this.deleted_collect(obj)
            }else{
                var obj={
                    subjectId:this.pageExamObj.subjectId,
                }
                this.add_collect(obj)
            }
        },
        // 标记此题
        stamp(){
            var type=null
            if(this.pageExamObj.isSign==1){
                type=2
            }else{
                type=1
            }
            var obj={
                subjectId:this.pageExamObj.subjectId,
                type:type,
                nodeId:this.examInfo.nodeId,
                testPaperId:''
            }
            add_sign(obj).then(res=>{
                if(res.data.code==0){
                    if(this.pageExamObj.classify=='1'){
                        if(this.pageExamObj.isSign==1){
                            this.pageExamObj.isSign==2
                            for(var i in this.radioExamList){
                                if(this.radioExamList[i].subjectId==this.pageExamObj.subjectId){
                                    this.radioExamList[i].isSign=2
                                }
                            }
                        }else{
                            this.pageExamObj.isSign==1
                            for(var i in this.radioExamList){
                                if(this.radioExamList[i].subjectId==this.pageExamObj.subjectId){
                                    this.radioExamList[i].isSign=1
                                }
                            }
                        }
                    }else if(this.pageExamObj.classify=='2'){
                        if(this.pageExamObj.isSign==1){
                            this.pageExamObj.isSign==2
                            for(var i in this.checkboxExamList){
                                if(this.checkboxExamList[i].subjectId==this.pageExamObj.subjectId){
                                    this.checkboxExamList[i].isSign=2
                                }
                            }
                        }else{
                            this.pageExamObj.isSign==1
                            for(var i in this.checkboxExamList){
                                if(this.checkboxExamList[i].subjectId==this.pageExamObj.subjectId){
                                    this.checkboxExamList[i].isSign=1
                                }
                            }
                        }
                    }else if(this.pageExamObj.classify=='3'){
                        if(this.pageExamObj.isSign==1){
                            this.pageExamObj.isSign==2
                            for(var i in this.estimateList){
                                if(this.estimateList[i].subjectId==this.pageExamObj.subjectId){
                                    this.estimateList[i].isSign=2
                                }
                            }
                        }else{
                            this.pageExamObj.isSign==1
                            for(var i in this.estimateList){
                                if(this.estimateList[i].subjectId==this.pageExamObj.subjectId){
                                    this.estimateList[i].isSign=1
                                }
                            }
                        }
                    }else if(this.pageExamObj.classify=='4'){
                        if(this.pageExamObj.isSign==1){
                            this.pageExamObj.isSign==2
                            for(var i in this.budingxiang){
                                if(this.budingxiang[i].subjectId==this.pageExamObj.subjectId){
                                    this.budingxiang[i].isSign=2
                                }
                            }
                        }else{
                            this.pageExamObj.isSign==1
                            for(var i in this.budingxiang){
                                if(this.budingxiang[i].subjectId==this.pageExamObj.subjectId){
                                    this.budingxiang[i].isSign=1
                                }
                            }
                        }
                    }else if(this.pageExamObj.classify=='5'){
                        if(this.pageExamObj.isSign==1){
                            this.pageExamObj.isSign==2
                            for(var i in this.jisuanfenxi){
                                if(this.jisuanfenxi[i].subjectId==this.pageExamObj.subjectId){
                                    this.jisuanfenxi[i].isSign=2
                                }
                            }
                        }else{
                            this.pageExamObj.isSign==1
                            for(var i in this.jisuanfenxi){
                                if(this.jisuanfenxi[i].subjectId==this.pageExamObj.subjectId){
                                    this.jisuanfenxi[i].isSign=1
                                }
                            }
                        }
                    }else if(this.pageExamObj.classify=='6'){
                        if(this.pageExamObj.isSign==1){
                            this.pageExamObj.isSign==2
                            for(var i in this.synthesisList){
                                if(this.synthesisList[i].subjectId==this.pageExamObj.subjectId){
                                    this.synthesisList[i].isSign=2
                                }
                            }
                        }else{
                            this.pageExamObj.isSign==1
                            for(var i in this.synthesisList){
                                if(this.synthesisList[i].subjectId==this.pageExamObj.subjectId){
                                    this.synthesisList[i].isSign=1
                                }
                            }
                        }
                    }else if(this.pageExamObj.classify=='7'){
                        if(this.pageExamObj.isSign==1){
                            this.pageExamObj.isSign==2
                            for(var i in this.shortAnswerList){
                                if(this.shortAnswerList[i].subjectId==this.pageExamObj.subjectId){
                                    this.shortAnswerList[i].isSign=2
                                }
                            }
                        }else{
                            this.pageExamObj.isSign==1
                            for(var i in this.shortAnswerList){
                                if(this.shortAnswerList[i].subjectId==this.pageExamObj.subjectId){
                                    this.shortAnswerList[i].isSign=1
                                }
                            }
                        }
                    }else if(this.pageExamObj.classify=='8'){
                        if(this.pageExamObj.isSign==1){
                            this.pageExamObj.isSign==2
                            for(var i in this.jisuanhuida){
                                if(this.jisuanhuida[i].subjectId==this.pageExamObj.subjectId){
                                    this.jisuanhuida[i].isSign=2
                                }
                            }
                        }else{
                            this.pageExamObj.isSign==1
                            for(var i in this.jisuanhuida){
                                if(this.jisuanhuida[i].subjectId==this.pageExamObj.subjectId){
                                    this.jisuanhuida[i].isSign=1
                                }
                            }
                        }
                    }else if(this.pageExamObj.classify=='9'){
                        if(this.pageExamObj.isSign==1){
                            this.pageExamObj.isSign==2
                            for(var i in this.anlifenxi){
                                if(this.anlifenxi[i].subjectId==this.pageExamObj.subjectId){
                                    this.anlifenxi[i].isSign=2
                                }
                            }
                        }else{
                            this.pageExamObj.isSign==1
                            for(var i in this.anlifenxi){
                                if(this.anlifenxi[i].subjectId==this.pageExamObj.subjectId){
                                    this.anlifenxi[i].isSign=1
                                }
                            }
                        }
                    }else if(this.pageExamObj.classify=='10'){
                        if(this.pageExamObj.isSign==1){
                            this.pageExamObj.isSign==2
                            for(var i in this.jisuan){
                                if(this.jisuan[i].subjectId==this.pageExamObj.subjectId){
                                    this.jisuan[i].isSign=2
                                }
                            }
                        }else{
                            this.pageExamObj.isSign==1
                            for(var i in this.jisuan){
                                if(this.jisuan[i].subjectId==this.pageExamObj.subjectId){
                                    this.jisuan[i].isSign=1
                                }
                            }
                        }
                    }else if(this.pageExamObj.classify=='11'){
                        if(this.pageExamObj.isSign==1){
                            this.pageExamObj.isSign==2
                            for(var i in this.zonghefenxi){
                                if(this.zonghefenxi[i].subjectId==this.pageExamObj.subjectId){
                                    this.zonghefenxi[i].isSign=2
                                }
                            }
                        }else{
                            this.pageExamObj.isSign==1
                            for(var i in this.zonghefenxi){
                                if(this.zonghefenxi[i].subjectId==this.pageExamObj.subjectId){
                                    this.zonghefenxi[i].isSign=1
                                }
                            }
                        }
                    }
                }
            }).catch(error=>{
                console.log(error)
            })
        },
    }
}
</script>

<style>
.el-popover{
        width:212px !important;
    }
    input{
        width: 50px;
        height: 50px;
        border: 1px solid black;
        line-height: 50px;
        text-align: center;
        border-radius: 10px;
        /* background-color: gainsboro; */
        outline: none;
    }
    .leftBox{
        font-size: 16px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
         font-weight: 400;
    }
    .rightBox{
        font-size: 16px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        margin-left: 10px;
        color: #C32222;
    }
</style>